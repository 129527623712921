import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useMemberCreate() {
  const toast = useToast()
  const statusOptions = [
    { label: 'Activate', value: 'Activate' },
    { label: 'Inactivate', value: 'Inactivate' },
  ]
  
  const addMember = async (member) => {
    try {
      const response = await store.dispatch('app-member/addMember', member);
      return response.data;
    } catch (error) {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error add member.',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    }
  }

  const uploadImage = async (images) => {
    try {
      const response = await store.dispatch('app-member/uploadImage', images);
      return response.data;
    } catch (error) {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error upload image',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    }
  }

  return {
    toast,
    addMember,
    uploadImage,
    statusOptions,
  }
}
